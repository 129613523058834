import React from 'react';
import {Container, Table} from "reactstrap";

export default function ThesisSupervision() {
    return (
        <Container className="m-2">
            <Table responsive>
                <thead>
                <tr>
                    <th>Year</th>
                    <th>Topic</th>
                    <th>Student</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>2022</th>
                    <th>Efficient Deployment and Placement of ML Models in a Unified Fog-cloud Environment</th>
                    <td>Mark Sümegi</td>
                </tr>
                <tr>
                    <th>2022</th>
                    <th>Query Rewrite Optimizations for the Nebulastream Data Management System</th>
                    <td>Anirudh Shetty</td>
                </tr>
                <tr>
                    <th>2021</th>
                    <th>Steady Query Execution Under Continuous Infrastructure Maintenance</th>
                    <td>Balint Rüb</td>
                </tr>
                <tr>
                    <th>2021</th>
                    <th>Efficient Operator Placement for Mutating Query Plans</th>
                    <td>Nithish Sankaranarayanan</td>
                </tr>
                <tr>
                    <th>2021</th>
                    <th>Benchmarking Stream Processing Engines for IoT Workload</th>
                    <td>Nabil Shaif</td>
                </tr>
                <tr>
                    <th>2020</th>
                    <th>Optimizing Stream Operator Placement using Reinforcement Learning</th>
                    <td>Dwi Prasetyo Adi Nugroho</td>
                </tr>
                </tbody>
            </Table>
        </Container>
    );
}