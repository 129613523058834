import React from 'react';
import {Container, Table} from 'reactstrap';

export default function Industry() {
  return (
      <Container className="m-2">
        <Table responsive>
          <thead>
          <tr>
            <th>Year</th>
            <th>Company</th>
            <th>Title</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">July 2019-Present</th>
            <td><a href="https://www.dima.tu-berlin.de/" target="_blank"
                   rel="noopener noreferrer">DIMA TU Berlin</a></td>
            <td>Research Associate</td>
          </tr>
          <tr>
            <th scope="row">Sept. 2016-July 2019</th>
            <td><a href="https://www.dilax.com/en" target="_blank"
                   rel="noopener noreferrer">Dilax</a></td>
            <td>Software Architect/Sr. Data Engineer</td>
          </tr>
          <tr>
            <th scope="row">Sept. 2014-Oct. 2016</th>
            <td><a href="https://www.teradata.de/" target="_blank"
                   rel="noopener noreferrer">TeraData</a></td>
            <td>Data Engineer</td>
          </tr>
          <tr>
            <th scope="row">Feb. 2013-Aug. 2014</th>
            <td><a href="https://www.iese.fraunhofer.de/" target="_blank"
                   rel="noopener noreferrer">Fraunhofer IESE</a></td>
            <td>Research Assistant</td>
          </tr>
          <tr>
            <th scope="row">Feb. 2013-Aug. 2014</th>
            <td><a href="https://www.icsy.de/" target="_blank"
                   rel="noopener noreferrer">ICSY TU Kaiserslautern</a></td>
            <td>Research Assistant</td>
          </tr>
          <tr>
            <th scope="row">Sept. 2009-July 2012</th>
            <td><a href="https://www.ericsson.com/en" target="_blank"
                   rel="noopener noreferrer">Ericsson</a></td>
            <td>Software Engineer</td>
          </tr>
          </tbody>
        </Table>
      </Container>
  );
}