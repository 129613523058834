import React from 'react';
import {Col, Container, Media, Row} from "reactstrap";
import me from "../assets/AnkitChaudhary.jpg";
import {
    FaGithub,
    FaLinkedin,
    FaTwitter,
    FiHash,
    FaHandSpock,
    FiMail,
    GoLocation, FaGraduationCap
} from "react-icons/all";

export default function AboutMe() {
    return (
        <Col>
            <Container fluid>
                <Media>
                    <Media className="m-2">
                        <Media object src={me} alt="AnkitChaudhary"
                               className="border border-dark img-thumbnail rounded float-left"
                               style={{maxHeight: 260, height: 260, minWidth: 100}}/>
                    </Media>
                    <Media body className="App">
                        <h1 className="display-4">Hi There! <FaHandSpock/></h1>
                        <hr color="black"/>
                        <p className="text-justify">Welcome to my home page. My name is <b>Ankit Chaudhary</b>. I am a
                            Database Researcher at <a href="https://www.dima.tu-berlin.de/">DIMA</a> (<a
                                href="https://www.tu-berlin.de/">TU Berlin</a>). My research interests lie in the intersection
                            of stream processing, fog/cloud computing, and big-data systems. My current research focuses
                            on building a robust and scalable optimizer for <a href="https://www.nebula.stream/">NebulaStream</a>. </p>
                        <p className="text-justify"> Before starting at TU Berlin, I have spent around
                            ten years working in the industry in various capacities. I have completed my M.Sc. in
                            Computer Science focused on distributed systems from <a href="https://www.uni-kl.de/">TU
                                Kaiserslautern</a>, and I hold an Engineering degree in Information Technology from <a
                                href="http://www.ipu.ac.in/">IP University Delhi</a>.
                        </p>
                    </Media>
                </Media>

                <Media body className="App">
                    <hr color="black"/>
                    <p>
                        <Row xl>
                            <Col>
                                <FiMail/> <b>Email:</b> <br/>
                                firstname.lastname@tu-berlin.de<br/>
                            </Col>
                            <Col>
                                <GoLocation/> <b> Address:</b> <br/>
                                Technische Universität Berlin <br/>
                                - Room EN 721 -<br/>
                                Fak. IV, FG DIMA, Sekr. EN7 <br/>
                                Einsteinufer 17, 10587 Berlin, Germany<br/>
                            </Col>
                            <Col>
                                <FiHash/> <b> Social:</b> <br/>
                                <a href="https://www.linkedin.com/in/ankit-chaudhary-0506b214" target="_blank"
                                   rel="noopener noreferrer" className="m-0 lead"><FaLinkedin/></a>
                                <a href="https://github.com/ankitgit" target="_blank" rel="noopener noreferrer"
                                   className="m-1 lead"><FaGithub
                                    color="black"/></a>
                                <a href="https://twitter.com/tweetctl" target="_blank" rel="noopener noreferrer"
                                   className="m-0 lead"> <FaTwitter/></a>
                                <a href="https://scholar.google.com/citations?user=lCRabcwAAAAJ&hl=en" target="_blank" rel="noopener noreferrer"
                                   className="m-0 lead"> <FaGraduationCap/></a>
                            </Col>
                        </Row>
                    </p>
                </Media>
            </Container>
        </Col>
    );
}