import React from 'react'
import {Container, Table} from "reactstrap";

export default function Teaching() {
    return (
        <Container className="m-2">
            <Table responsive>
                <thead>
                <tr>
                    <th>Semester</th>
                    <th>Subject</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>SS 2022</td>
                    <td><a
                        href="https://moseskonto.tu-berlin.de/moses/modultransfersystem/bolognamodule/beschreibung/anzeigen.html?number=40804&version=1"
                        target="_blank" rel="noopener noreferrer">In-Memory Databases On Modern Hardware</a></td>
                </tr>
                <tr>
                    <td>WS 2021/2022</td>
                    <td>Programmierpraktikum: Datenbankprojekt</td>
                </tr>
                <tr>
                    <td>SS 2021</td>
                    <td><a
                        href="https://www.dima.tu-berlin.de/fileadmin/fg131/LV-Ankuend/WS1920/Lehrveranstaltungen_DIMA_BDAPRO.pdf"
                        target="_blank" rel="noopener noreferrer">BDAPRO:
                        Big Data Analytics Project</a></td>
                </tr>
                <tr>
                    <td>WS 2020/2021</td>
                    <td><a
                        href="https://www.dima.tu-berlin.de/fileadmin/fg131/LV-Ankuend/WS1920/Lehrveranstaltungen_DIMA_BDAPRO.pdf"
                        target="_blank" rel="noopener noreferrer">BDAPRO:
                        Big Data Analytics Project</a></td>
                </tr>
                <tr>
                    <td>SS 2020</td>
                    <td><a
                        href="https://moseskonto.tu-berlin.de/moses/modultransfersystem/bolognamodule/beschreibung/anzeigen.html?number=40804&version=1"
                        target="_blank" rel="noopener noreferrer">In-Memory Databases On Modern Hardware</a></td>
                </tr>
                <tr>
                    <td>SS 2020</td>
                    <td><a
                        href="https://moseskonto.tu-berlin.de/moses/modultransfersystem/bolognamodule/beschreibung/anzeigen.html?number=40353&version=4&sprache=2"
                        target="_blank" rel="noopener noreferrer">Big Data Analytics Seminar</a></td>
                </tr>
                <tr>
                    <td>WS 2019/2020</td>
                    <td><a
                        href="https://www.dima.tu-berlin.de/fileadmin/fg131/LV-Ankuend/WS1920/Lehrveranstaltungen_DIMA_BDAPRO.pdf"
                        target="_blank" rel="noopener noreferrer">BDAPRO:
                        Big Data Analytics Project</a></td>
                </tr>
                <tr>
                    <td>WS 2019/2020</td>
                    <td><a
                        href="https://www.dima.tu-berlin.de/fileadmin/fg131/LV-Ankuend/WS1920/Lehrveranstaltungen_DIMA_ROC_Research-Oriented-Course-on-Data-Science-and-Engineering-Systems-and-Technologies.pdf"
                        target="_blank" rel="noopener noreferrer">Research Oriented Course (ROC) on
                        Data Science and Engineering Systems and Technologies</a></td>
                </tr>
                </tbody>
            </Table>
        </Container>
    );
}