import React, {useState} from 'react';
import './App.css';
import AboutMe from "./pages/AboutMe";
import Container from "reactstrap/es/Container";
import Publications from "./pages/Publications";
import Industry from "./pages/Industry";
import ThesisSupervision from "./pages/ThesisSupervision";
import Teaching from "./pages/Teaching";
import Footer from "./pages/Footer";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import {FiBook, GiComputing, GiTeacher, MdSupervisorAccount} from "react-icons/all";

function App() {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    return (
        <div>
            <Container>
                <AboutMe/>
            </Container>
            <Container className="">
                <hr color="success" className=""/>
                <Nav tabs pills>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => {
                                toggle('1');
                            }}
                        >
                            <h5><FiBook/> Publications</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => {
                                toggle('2');
                            }}
                        >
                            <h5><GiComputing/> Work Experience</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '3'})}
                            onClick={() => {
                                toggle('3');
                            }}
                        >
                            <h5><MdSupervisorAccount/> Thesis Supervision</h5>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '4'})}
                            onClick={() => {
                                toggle('4');
                            }}
                        >
                            <h5><GiTeacher/> Teaching</h5>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Publications/>
                    </TabPane>
                    <TabPane tabId="2">
                        <Industry/>
                    </TabPane>
                    <TabPane tabId="3">
                        <ThesisSupervision/>
                    </TabPane>
                    <TabPane tabId="4">
                        <Teaching/>
                    </TabPane>
                </TabContent>
            </Container>
            <Footer/>
        </div>
    );
}

export default App;
