import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const ImprintAndPrivacy = (props) => {

    const {
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    return (
        <div>
            <Button color="link" onClick={toggle}>Imprint and Data Privacy</Button>
            <Modal isOpen={modal} toggle={toggle} className={className} size="xl">
                <ModalHeader toggle={toggle} close={closeBtn}>Imprint and Data Privacy</ModalHeader>
                <ModalBody className="m-4">
                    <h5>Imprint</h5>
                    <span className="small text-decoration-none">
                        <p>
                            The following Privacy Policy refers to the my personal website which falls under the responsibility of <b>Ankit Chaudhary</b>.
                        </p>
                        <h6>Editorial and Content Responsibility</h6>
                        <p>
                            <b>Ankit Chaudhary</b><br/>
                            Technische Universität Berlin <br/>
                            Database Systems and Information Management Group<br/>
                            Electrical Engineering and Computer Science (Faculty IV)<br/>
                            EN 708, Einsteinufer 17<br/>
                            10587 Berlin <br/>
                            E-Mail: ankit.chaudhary(at)tu-berlin.de<br/>
                        </p>
                        <h6>Legal notices on copyright</h6>
                        The webpage layout, the graphics used, and all other content are protected by copyright.
                    </span>
                    <hr className="my-4"/>
                    <h5>Data Privacy</h5>
                    <span className="small text-justify">
                        <p className="text-justify">
                            Thank you for your interest in this website. The protection of the personal data of visitors of our page is very important for us. Therefore we want to inform you about data security.<br/>
                        </p>
                        <p>
                            The following Privacy Policy refers to the website which falls under the responsibility of Ankit Chaudhary. The website is not concerned with any commercial transactions or with the exchange of data for marketing purposes and is used for presenting information about me, my research work, and the teaching offered by DIMA at TU Berlin.<br/>
                        </p>
                        <h6> Subject of Data Privacy</h6>
                        <p>
                            Data privacy covers personal data. According to art. 4 par. 1 of DSGVO these are data referring to an identified or identifiable individual, hence all data which could be used to identify you. This applies for data such as name, private address, e-mail address, telephone number but also to usage data such as your IP address. Of course I observe the legal requirements
                            of data privacy and other applicable regulations. I am committed to ensure that you can trust me concerning your personal data. Therefore transfers of sensitive data are encrypted. In addition our websites are protected against damage and unauthorized access by technical measures.
                        </p>
                        <h6>Data Collection and Storage</h6>
                        <p>
                            For the usage of our website the registration of your personal data is not necessary in general.<br/>
                        </p>
                        <h6>Collection and Storage of Usage Data</h6>
                        <p>
                            For the optimization of our websites we collect and store traffic data to analyze trends in site use and to administer the site (such as visited website, date and time of access, the website which you are coming from and so on) for a period of two weeks. After that they will be deleted automatically.
                        </p>
                        <p>
                            These web pages are hosted on servers of Github Inc., 88 Colin P Kelly Jr St, San Francisco, CA 94107, USA. Please note the GitHub Privacy Policy, and GitHub Global Privacy Practices, and Privacy Shield Compliance declaration.
                        </p>
                        <h6>Links</h6>
                        <p>
                            This web site contains links to other sites. Please be aware that the I am not responsible for the contents and the privacy practices of such other sites. We encourage our users to be aware of that when they leave our site and to read the
                            privacy statements of these third party sites. This privacy statement applies solely to information collected by this web site.<br/>
                        </p>

                        <h6>Right of Access to Personal Data</h6>

                        <p>
                            You can retrieve information about your stored personal data without giving reasons at any time free of charge. <b> Please contact the address provided below</b>. We will be pleased to assist you if you have any further questions about our data privacy information. Please note that data
                            privacy regulations and handling of data privacy can change from time to time making it necessary to inform oneself about changes of data privacy laws and company policies. This data privacy statement does not cover linked websites of external webservers.
                        </p>

                        We invite you to contact us if you have questions about this policy at:
                        <p>
                            <b>Ankit Chaudhary</b><br/>
                            Technische Universität Berlin<br/>
                            Database Systems and Information Management Group<br/>
                            Electrical Engineering and Computer Science (Faculty IV)<br/>
                            EN-708, Einsteinufer 17<br/>
                            10587 Berlin<br/>
                            E-Mail: ankit.chaudhary[@]tu-berlin.de
                        </p>
                    </span>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>OK</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ImprintAndPrivacy;