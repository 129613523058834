import React from 'react';
import {Container, Table} from "reactstrap";
import {FiDownload, GiSpiderWeb} from "react-icons/all";

export default function Publications() {
    return (
        <Container className="m-2">
            <Table responsive>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Venue</th>
                    <th>Title</th>
                    <th>Link</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>EDBT 2023</td>
                    <td>
                        <strong> Incremental Stream Query Merging </strong>
                        <br/>
                        <font color="#00008b"> <b> Ankit Chaudhary </b></font>, Jeyhun Karimov, Steffen Zeuch, Volker Markl
                    </td>
                    <td><a href="https://www.nebula.stream/paper/chaudhary_edbt2023.pdf" target="_blank"
                           rel="noopener noreferrer"><FiDownload/>pdf</a></td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Datenbank-Spektrum 2022</td>
                    <td>
                        <strong> NebulaStream: Data Management for the Internet of Things </strong>
                        <br/>
                        Steffen Zeuch, Xenofon Chatziliadis, <font color="#00008b"> <b> Ankit Chaudhary </b></font>, Dimitrios Giouroukis, Philipp M Grulich,
                        Dwi Prasetyo Adi Nugroho, Ariane Ziehn, Volker Mark
                    </td>
                    <td><a href="https://link.springer.com/article/10.1007/s13222-022-00415-0" target="_blank"
                           rel="noopener noreferrer"><GiSpiderWeb/>link</a></td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>SIGMOD 2021</td>
                    <td>
                        <strong> ExDRa: Exploratory Data Science on Federated Raw Data </strong>
                        <br/>
                        Sebastian Baunsgaard, Matthias Boehm, <font color="#00008b"> <b> Ankit Chaudhary </b></font>, Behrouz Derakhshan, Stefan Geißelsöder,
                        Philipp Marian Grulich, Michael Hildebrand, Kevin Innerebner, Volker Markl, Claus Neubauer,
                        Sarah Osterburg, Olga Ovcharenko, Sergey Redyuk, Tobias Rieger, Alireza Rezaei Mahdiraji,
                        Sebastian Benjamin Wrede, Steffen Zeuch
                    </td>
                    <td><a href="https://www.redaktion.tu-berlin.de/fileadmin/fg131/Publikation/Papers/ExDRa_SIGMOD-21_preprint.pdf" target="_blank"
                           rel="noopener noreferrer"><FiDownload/>pdf</a></td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>VLIoT 2020</td>
                    <td>
                        <strong> NebulaStream: Complex Analytics Beyond the Cloud </strong>
                        <br/>
                        Steffen Zeuch, Eleni Tzirita Zacharatou, Shuhao Zhang, Xenofon Chatziliadis, <font color="#00008b"> <b> Ankit Chaudhary </b></font>,
                        Bonaventura Del Monte, Dimitrios Giouroukis, Philipp M. Grulich, Ariane Ziehn, Volker Markl
                    </td>
                    <td><a href="https://www.nebula.stream/paper/zeuch_vliot.pdf" target="_blank"
                           rel="noopener noreferrer"><FiDownload/>pdf</a></td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td>EDBT 2020</td>
                    <td>
                        <strong> Governor: Operator Placement for a Unified Fog-Cloud Environment </strong>
                        <br/>
                        <font color="#00008b"> <b> Ankit Chaudhary </b></font>, Steffen Zeuch, Volker Markl
                    </td>
                    <td><a
                        href="http://www.redaktion.tu-berlin.de/fileadmin/fg131/Publikation/Papers/Chaudhary_Governor_EDBT2020_preprint.pdf"
                        target="_blank"
                        rel="noopener noreferrer"><FiDownload/>pdf</a></td>
                </tr>
                <tr>
                    <th scope="row">6</th>
                    <td>CIDR 2020</td>
                    <td>
                        <strong>The NebulaStream Platform: Data and Application Management for the Internet of
                            Things</strong>
                        <br/>
                        Steffen Zeuch, <font color="#00008b"> <b> Ankit Chaudhary </b></font>, Bonaventura Del Monte, Dimitrios Giouroukis, Haralampos
                        Gavriilidis, Philipp M. Grulich, Sebastian Breß, Jonas Traub, Volker Markl
                    </td>
                    <td><a href="https://arxiv.org/pdf/1910.07867" target="_blank"
                           rel="noopener noreferrer"><FiDownload/>pdf</a></td>
                </tr>
                </tbody>
            </Table>
        </Container>
    );
}